/* eslint-disable */
///-------------------------------------------------------------------------------------------------
// summary:	Authentication service class
///-------------------------------------------------------------------------------------------------

import Api from './Api';
export default {
  
  login(credentials) {
    const response = Api().post('/1/login', credentials);
    return response;
  },
  
  logout(credentials) {
    const response = Api().post('/1/logout', credentials);
    return response;
  },

  register(credentials) {
    const response = Api().post('/1/signup', credentials);
    return response;
  },

  link(credentials) {
    const response = Api().post('/1/linkAccount', credentials);
    return response;
  },

  // This just reauthorize the existing token (or tries, at least); 
  // A fresh token is returned, which we should replace our old token with
  authorize() {
    const response = Api().post("/1/authorize", {});
    return response;
  },
  
  forgotPassword(email) {
    const response = Api().post("/1/forgotPassword", {email: email});
    return response;
  },
  resetPassword( email , token , newPassword ) {
    const response = Api().post( "/1/resetPassword" , {email:email, resetToken: token, newPassword: newPassword });
    return response;
  }
 
}

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import VueAnalytics from 'vue-analytics'
import router from './router'
import store from './store'

import { BootstrapVue, BootstrapVueIcons, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@/assets/css/custom.scss'

import axios from 'axios'
import VueJsDialog from 'vuejs-dialog'

// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import Authentication from '@/services/AuthenticationService'
import _ from "lodash"

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)
Vue.use(VueJsDialog)

Vue.config.productionTip = false

axios.defaults.headers.common[ 'x-access-token' ] = null


/* -------------------------------- constants -------------------------------- */
const accessTokenKey = "x-access-token";

Vue.use(VueAnalytics, { // G-W2JYR5NCVM  or 300868870     this is the old one: UA-9964551-36 (mission manager)
  id: '300868870',
  router,
  autoTracking: {
    pageviewTemplate (route) {
      let o = {}
      if (typeof route.matched !== 'undefined') { // Figure if matched is present, it's an array with at least 1 element
        o.title = route.matched[ 0 ].name // seems to be the same as route.name, but for consistency used the matched name
        if (route.matched[ 0 ].path !== '*') { // let's capture the 404's so this is the "catch-all" we will replace.
          o.path = route.matched[ 0 ].path
          o.location = route.matched[ 0 ].path // The matched route, which is the raw route definition, as our location
        } else {
          o.path = route.path // we hit *, which would mean a 404, so use the original url so we capture it.
          o.location = window.location.href
        }
      } else {
        o.title = route.name
        o.path = route.path
        o.location = window.location.href
      }
      return o
    }
  }
})

new Vue({
  data: {
    initialized   : false,
    tenant        : null,
    account       : null,
    badge         : null,
    user          : null,
    TZ            : null,
    logo          : null,
    revenueCenters: null,
    portal        : null,
    enableFooter  : true,
    footerButtons : []
  },
  created() {
    if( ! this.initialized ) {
      this.SystemInit();
    }
  },
  methods: {
    async SystemInit() {
      
      if( ! this.initialized ) {
        try {
          if( this.getAccessToken()) {
            let response = await Authentication.authorize();

            if( ! response.data["x-access-token"] ) {
              this.removeAccessToken();
              this.$router.push("/");
            } else {
              let token = response.data[ "x-access-token"];
              this.setAccessToken( token );
            }
          }

        } catch( error ) {
          localStorage.removeItem( 'x-access-token' );
        }

        this.setFooterButtonsDefault();

        this.initialized = true;  // set this AFTER all init has taken place.
      }
    },
    isShowFooter() {
      if( !this.enableFooter ) {
        return false;
      }
      return this.isLoggedIn('footer');
    },
    setFooterButtons( buttons ) {
      this.footerButtons = buttons;
    },
    setFooterButtonsDefault(){
      this.enableFooter = true;
      let buttons =  [
        { title: "Home", to: { path: '/home' } , icon: "fa fa-home"},
        // { title: "Order", to: { name: 'order' } , icon: "fa fa-cutlery"},
        { title: "Apps", to: { name: 'apps' } , icon: "fa fa-server"},
        // { title: "Pay", to: { name: 'payment' } , icon: "fa fa-dollar"},
        { title: "More", to: { name: 'more' } , icon: "fa fa-ellipsis-h"}
      ]
      this.setFooterButtons( buttons );
    },
    getAccessToken() {
      let token = localStorage.getItem(accessTokenKey);
      return token;
    },
    setAccessToken( token ) {
      localStorage.setItem( accessTokenKey, token);
    },
    removeAccessToken() {
      localStorage.removeItem(accessTokenKey);
    },
    login( data ) {
      console.log( "login() data" , data );
      this.saveConfig( data );
    },
    logoff() {
      this.removeAccessToken();
      localStorage.removeItem( 'team-data' );
      this.$router.push("/");
    },
    isLoggedIn(source) {
      if( this.getAccessToken() ) {
        if( ! this.hasUser() ) {
          this.setUser( localStorage.getItem('user'));
        }
        return true;
      } else {
        return false;
      }
    },
    hasUser() {
      if( this.user !== null ) {
        return true;
      } else {
        return false;
      }
    },
    setUser( user ) {
      this.user = user;
    },
    saveConfig( data ) {

      this.setAccessToken( data[ 'x-access-token'] );
      delete data[ 'x-access-token'];

      localStorage.setItem( 'team-data' , JSON.stringify(data));

      this.tenant = data.account.tenant;
      this.TZ     = this.tenant.TZ;
      this.logo   = this.tenant.logo;
      delete data.account.tenant;

      this.user    = data.portal.user;
      delete data.portal.user;
      
      this.portal         = data.portal;
      this.account        = data.account;
      this.badge          = data.badge;
    },
    loadConfig() {
      let data = JSON.parse(localStorage.getItem( 'team-data' ));

      if( ! data ) {
        return false;
      }

      this.tenant = data.account.tenant;
      this.TZ     = this.tenant.TZ;
      this.logo   = this.tenant.logo;
      delete data.account.tenant;

      this.user    = data.portal.user;
      delete data.portal.user;
      this.portal         = data.portal;
      this.account        = data.account;
      this.badge          = data.badge;

      return true;
    },
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')

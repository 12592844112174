<template>
  <div id="app" class="w-100">
    <Header v-if="$root.isLoggedIn('header')"/>
    <!-- <div class="app-page" > -->
      <router-view style="" />
    <!-- </div> -->
    <Footer v-if="$root.isShowFooter()"/>
  </div>
</template>

<script>
/* eslint-disable */
import Header from './components/Header';
import Footer from './components/Footer';

export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      user       : null,
      wolfie     : "this is app.vue"
    }
  },
  mounted () {
    this.$store.dispatch('initialLoad')
  },
  methods: {
    swUpdated() {
      console.log( " *****************************       The swUpdated() event just fired inside App.vue" );
    }
  }
}
</script>

<style language="scss" scoped >

 
</style>

/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
// import Api from '@/services/Api.js'
Vue.use(Vuex)

// storageData is now a HUGE object - this may not be the best way to manage this.

let storageData = JSON.parse(localStorage.getItem('iCareTeam'))
export default new Vuex.Store({
  state: {
    userLoggedIn: false,
    property    : {}
  },
  mutations: {
    initial_load (state) {
      state.userLoggedIn = storageData && storageData.userLoggedIn
    },
    userLogIn (state, payload) {
      if (!storageData && payload) {
        storageData = {}
      }
      if (payload) {
        storageData['userLoggedIn'] = true
        state.userLoggedIn = true
        storageData['token'] = payload
      } else {
        storageData['userLoggedIn'] = false
        storageData['token'] = null
        state.userLoggedIn = false
      }
    },
  },
  actions: {
    initialLoad (context) {
      context.commit('initial_load')
    },
    userLogIn (context, payload) {
      // if( payload['x-access-token']) {
      //   Api.headers[ 'x-access-token'] = payload['x-access-token'];
      // }
      context.commit('userLoggedIn', payload)
    },
  },
  modules: {
  }
})

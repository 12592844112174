<template>
  <div class="container main-page">
    <!-- <h2>Application Selector</h2> -->
    <div class="align:center;">
      <b-button class="app-select-button" style="background-color:salmon;" @click="runApp('tickets')"><i class="fa fa-ticket"/>Tickets</b-button>
      <b-button class="app-select-button" style="background-color:cadetblue;" @click="runApp('purchaseHistory')"><i class="fa fa-file-text-o"/>Receipts</b-button>

    </div>

  </div>
</template>

<script>
/* eslint-disable */
// import Authentication from '@/services/AuthenticationService'
// import GeneralService from '@/services/GeneralService'

export default {
  name: 'applications',
  data () {
    return {
      pageReady: true
    }
  },
  async mounted() {
  },
  methods: {
    home() {
      this.$router.push( { name: 'home' });
    },
    cancel() {
      this.$router.push({name:'home'});
    },
    runApp( appName ) {
      this.$router.push( { name: appName });
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

/* eslint-disable */
///-------------------------------------------------------------------------------------------------
// summary:	General Services
///-------------------------------------------------------------------------------------------------

import Api from './Api';
import moment from "moment-timezone";

export default {
 
  getExample() {
    const response = Api().get('/1/getExample');
    return response;
  },

  getBalance() {
    const response = Api().get('/2/balance');
    return response;
  },

  getTransactions( beginDate = null , endDate = null) {
    const response = Api().get('/2/transactions' ,{ params: {beginDate: beginDate, endDate: endDate}});
    return response;
  },

  // news-item is the default, but I added this here if/when we want separate news for the mobile app so that I can remember this param exists
  getNews( noteType = "news-item" , locations = null ) {
    const response = Api().get('/1/getNews' ,{ params: {noteType: noteType , locations: locations }});
    return response;
  },

  getNewTransaction(tz) {
    let   beginDate = moment().tz(tz).subtract(10,"seconds").toString();
    let   endDate   = moment().tz(tz).add(2,"seconds").toString();

    // let   beginDate = moment().subtract(10,"seconds").toString();
    // let   endDate   = moment().add(2,"seconds").toString();
    
    const response  = Api().get('/2/transactions' ,{ params: {beginDate: beginDate, endDate: endDate}});
    return response;
  },

  getRevenueCenters() {
    const response = Api().get('/1/revenueCenters');
    return response;
  },
 
}

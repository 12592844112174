<template>
  <div class="new-post-component">
    <h4 @click="addNew()">Start a new post</h4>
    <p>Share with your community</p>
    <div class="new-post-icons">
      <i class="fa fa-camera"></i>
      <i class="fa fa-video"></i>
      <i class="fa fa-file"></i>
    </div>
    <div>Write an article on iCare Team</div>
  </div>
</template>
<script>
export default {
  props: {
  },
  data() {
    return {

    }
  },
  methods: {
    addNew() {
      alert( "Feature to be developed" );
      this.$emit( "addNew" );
    }
  }
}
</script>
<style lang="scss" scoped>
  /* Styles managed in assets/css/custom.scss  */
</style>
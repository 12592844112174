<template>
  <div class="login-form-container">
    <div class="container login-form main-page row">
      <div class="col-xs-12 col-sm-8 col-md-6 col-lg-4 col-xl-4">
        <img src="@/assets/img/iCareTeamLogo.png"  class="page-logo" alt="iCare Team Logo" @click="$router.push('/')" />
        <div class=" mb-4 ml-auto mr-auto">
          <h4>iCare Team Site Login</h4>
          <!-- <hr> -->
          <p style="font-size:.8em;padding:.5em;">Remember: For God so loved the world, that he gave his only begotten Son, that whosoever believeth in him should not perish, but have everlasting life.” - John 3:16</p>
          <!-- <hr> -->
          <p>If you have an account, please Log In </p>
        </div>
        <div v-if="errorMessage" class="col-12 font-weight-bolder text-center mt-4 text-danger">{{errorMessage}}</div>
        <div v-if="!isChooseTenant" class="col-12">
          <div class="position-relative">
            <b-form-input class="email-input mb-0 pl-5" v-model="email" placeholder="Email" ></b-form-input>
            <i class="fa fa-envelope email-icon"/>
          </div>
          <div class="position-relative">
            <b-form-input class="password-input mt-2 pl-5" type="password" v-model="password" placeholder="Password"  @keyup.enter.prevent="signIn"></b-form-input>
            <i class="fa fa-lock password-icon"/>
          </div>
          <div v-if="requiresActivation" class="position-relative">
            <b-form-input class="activation-input mt-5 pl-5" type="text" v-model="signupCode" placeholder="Activation Code (sent via email)"></b-form-input>
            <i class="fa fa-bookmark activation-icon"/>
          </div>
        </div>
        <div v-if="!isChooseTenant" class="col-12 mt-4  ml-auto mr-auto">
          <!-- <b-button class="cancel-button" variant="info" @click="$router.push({name:'public'})">Cancel</b-button> -->
          <b-button class="w-100 sign-in-button" variant="warning" @click="signIn">Log In</b-button>

          <div class="w-100 register-button" @click="forgotPassword">Forgot Password</div>
          <div class="w-100 register-button" @click="register()">Register for Access</div>
          <div class="w-100 register-button" @click="cancel">Back to Home Page</div>

          <!-- <div class="w-100 register-button" @click="register">Register to create an Account</div> -->
        </div>
        <div v-if="isChooseTenant" class="col-12 mt-4 ml-auto mr-auto">
          <h4>Choose Company</h4>
          <ul v-for="(tenant,index) in tenants" :key="index">
              <li style="cursor:pointer;color:white;font-size:24px;" @click="selectTenant(tenant.domain)">{{tenant.tenantName}}</li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable */
import Authentication from '@/services/AuthenticationService'
import GeneralService from '@/services/GeneralService'

export default {
  name: 'login',
  data () {
    return {
      pageReady         : false,
      email             : "",
      password          : "",
      errorMessage      : "",
      signupCode        : "",
      requiresActivation: false,
      isChooseTenant    : false,
      domain            : null,
      tenants           : []
    }
  },
  async mounted() {
    console.log( "login.vue monuted: root=", this.$root );
    let token = this.$root.getAccessToken(); // localStorage.getItem( 'x-access-token' );
    if( token ) {
      await this.authorize( token );
    } else {
      this.pageReady = true;
    }
  },
  methods: {
    home() {
      this.$router.push( { name: 'home' });
    },
    cancel() {
      this.$root.removeAccessToken();
      this.$router.push({name:'public'});
    },
    async authorize( token ) {
      try {
        // let response = await Authentication.authorize( token );
        // let data = response.data;
        // this.$root.setAccessToken(data['x-access-token'] )
        // console.log( "authorized: " , data );
        this.home();
      } catch( error ) {
        this.pageReady = true;
      }
    },
    forgotPassword() {
      alert( "This feature has not yet been implemented" );
    },
    register() {
      this.$router.push("/register");
    },
    async selectTenant( domain ) {
      this.domain = domain;
      this.isChooseTenant = false;
      await this.signIn();
    },
    async signIn () {

      if( this.email.length === 0 || this.password.length === 0) {
        this.errorMessage = "Please enter email and password"
        return
      }

      let credentials = {
        'email'     : this.email,
        'password'  : this.password,
        'signupCode': this.signupCode
      }

      if( this.domain ) {
        credentials["domain"] = this.domain;
      }

      try {
        let response = await Authentication.login(credentials)

        if( response.status === 403 ) {
          if( response.data && response.data.unVerified === true ) {
            this.requiresActivation = true;
            this.errorMessage       = "";
            return;
          }
        }

        if( response.status === 203 ) {
          this.isChooseTenant = true;
          this.tenants = response.data;
          console.log( "tenants" ,  this.tenants )
          // requires aactivation code
          // let alertMessage = `Thank you.  Your account has not yet been verified; Press OK and you will be prompted to enter the registration code that was emailed to you after registration. If you cannot find this email, remember to check your SPAM folder.`;
          // await this.$dialog.alert(alertMessage);
          // this.requiresActivation = true;
          return;
        }

        if( response.status === 200 ) {

          let data = response.data;
console.log( "response 200" , data );
          this.$root.login( data );
console.log( "back from root.login()" );
          this.home();
console.log( "back from home()");

        } else {
          this.errorMessage = response.data.message;
        }

      } catch( err ) {
        this.errorMessage = err.message;
      }
    }
  }
}
</script>

<style lang="scss" scoped >

// go see: assets/css/custom.css

// .login-form-container{

//   min-height: 1024px;
//   width:100%;

//   background: -moz-linear-gradient(top,  rgba(42,44,43,1) 0%, rgba(42,44,43,0.9) 52%, rgba(42,44,43,1) 100%); /* FF3.6-15 */
//   background: -webkit-linear-gradient(top,  rgba(42,44,43,1) 0%, rgba(42,44,43,0.9) 52%, rgba(42,44,43,1) 100%); /* Chrome10-25,Safari5.1-6 */
//   background: linear-gradient(to bottom,   rgba(42,44,43,1) 0%, rgba(42,44,43,0.9) 52%, rgba(42,44,43,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#659dbd', endColorstr='#d5dee5',GradientType=0 ); /* IE6-9 */

//   // background: -moz-linear-gradient(top,  rgba(101,157,189,1) 0%, rgba(101,157,189,0.9) 52%, rgba(213,222,229,1) 100%); /* FF3.6-15 */
//   // background: -webkit-linear-gradient(top,  rgba(101,157,189,1) 0%,rgba(101,157,189,0.9) 52%,rgba(213,222,229,1) 100%); /* Chrome10-25,Safari5.1-6 */
//   // background: linear-gradient(to bottom,  rgba(101,157,189,1) 0%,rgba(101,157,189,0.9) 52%,rgba(213,222,229,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//   // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#659dbd', endColorstr='#d5dee5',GradientType=0 ); /* IE6-9 */
// // margin-top: -4rem !important;
//   // margin-top: -4rem;
//   .login-form {
//     // position: fixed;
//     //top: 50%;
//     //left: 50%;
//     // transform: translate(-50%, -50%) !important;
//     // max-width: 480px;


//     h1 {
//       color: white;
//     }
//     h2 {
//       color: white;
//     }
//     h3 {
//       color: white;
//     }
//     h4 {
//       color: white;
//     }

//     p {
//       color: white;
//       // todo: center this
//     }

//     .page-logo {
//       border-radius:.5em;
//       height:24vw;
//       margin-left:auto;
//       margin-right:auto;
//       margin-top:1em;
//       margin-bottom:1em;
//     }

//     .email-input{
//       border-radius: 3.25rem !important;
//       height: calc(1.5em + 1.75rem + 2px) !important;
//       border-bottom: 0;
//     }
//     .password-input{
//       border-radius: 3.25rem !important;
//       height: calc(1.5em + 1.75rem + 2px) !important;
//     }
//     .activation-input{
//       border-radius: 3.25rem !important;
//       height: calc(1.5em + 1.75rem + 2px) !important;
//     }
//     .sign-in-button{
//       height: calc(1.5em + 1.75rem + 2px) !important;
//       width:40%;
//       border-radius: 3.25rem !important;
//       // background-color: #159DBD;
//       // border: #159DBD;
//     }
//     .cancel-button {
//       margin-top:1em;
//       height:calc(1.5em + 1.75rem + 2px);
//       width:40%;
//       border-radius:3.25em;
//     }
//     .register-button {
//       // margin-top:1em;
//       // height:calc(1.5em + 1.75rem + 2px);
//       // border-radius:3.25em;
//       margin-top:.75em;
//       cursor:pointer;
//       text-align:right;
//       text-decoration:underline;
//       color: white;
//     }

//     .sign-in-button.btn-primary:hover{
//       background-color: #259DBD;
//       border-color: #259DBD;
//     }

//     // .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
//     //     color: #fff;
//     //     background-color: #659DBD;
//     //     border-color: #659DBD;
//     // }

//     .email-icon{
//       position: absolute;
//       top: 17px;
//       left: 20px;
//       font-size: 19px;
//     }
//     .password-icon{
//       position: absolute;
//       top: 15px;
//       left: 20px;
//       font-size: 19px;
//     }
//     .activation-icon{
//       position: absolute;
//       top: 17px;
//       left: 20px;
//       font-size: 19px;
//     }

//     @media (max-width:640px ) {
//       .login-logo {
//         border-radius:.5em;
//         height:125px;
//       }
//     }

//   }
//}
</style>

<template>
  <div class="recommendation-component">
    <h5>{{title}}</h5>
    <p v-if="description.length>0">{{description}}</p>
    <ul>
      <li v-for="(item,index) in itemList" :key="'itemList-'+index">{{item}}</li>
    </ul>
  </div>
</template>
<script>
import Moment from "moment";
export default {
  props: { 
    title      : { type: String, default: "Recommendations"},
    description: { type: String, default: "" },
    itemList   : { type: Array, default: () => []}
  },
  methods: {
    moment(d) {
      return Moment(d);
    }
  }
}
</script>
<style lang="scss" scoped>
  /* Styles managed in assets/css/custom.scss  */
</style>
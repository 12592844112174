<template>
  <b-container fluid class="main-page">
    <b-row>
      <b-col md="4" sm="12">
        <h2>Meal Ordering</h2>
        <div class="shopping-cart-status"><i class="fa fa-shopping-cart"></i><div v-if="cart.length">{{cart.length}}</div></div>
        <p>This section is not yet implemented, but this gives you the general idea - this is NOT how it will ultimately be.</p>
        <h4>Lite Bites Cafe</h4>
        <ul>
          <li v-for="(item,index) in itemList" :key="'items-'+index" @click="cart.push(item)">{{item}}</li>
        </ul>
        
      </b-col>
      <b-col  md="4" sm="12">
        <h4>Cart</h4>
        <ul class="menu-list">
          <li v-for="(item,index) in cart" :key="'cart-'+index" @click="cart.splice(index,1)">{{item}}</li>
        </ul>
      </b-col>
    </b-row>
  </b-container>

</template>

<script>
/* eslint-disable */
import Authentication from '@/services/AuthenticationService'
import GeneralService from '@/services/GeneralService'

export default {
  name: 'login',
  data () {
    return {
      pageReady: true,
      itemList: ['Hamburger','Cheese Burger','French Fries','Onion Rings', 'Coke', 'Sprite','Water'],
      cart: []
    }
  },
  async mounted() {
    window.scrollTo(0,-1);
  },
  methods: {
    home() {
      this.$router.push( { name: 'home' });
    },
    cancel() {
      this.$router.push({name:'home'});
    },
  }
}
</script>

<style lang="scss" scoped>

</style>

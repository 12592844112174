/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { version } from "../package.json"

var appVersion = "";

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )

      Notification.requestPermission( (status) => {
        console.log( "Request permission for push" , status );
      })
    },
    registered () {
      console.log(`Version ${version} Service worker has been registered.  (old ${appVersion})`)
      appVersion = version;

    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated ( registration ) {
      console.log(`New content is available.  New: ${version}, Old: ${appVersion}`)
      // alert( `New Version Is Available. Press OK, then close the browser and launch again to activate the new version. Be sure to exit all instances of the application or the new version will not fully update.` );
      // window.reload();

      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });

      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      );
    },
    offline () {
      alert( "we are offline" );
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

<template>
  <b-container class="main-page" >
    <b-row>
      <div v-if="$root.logo" ref="top" class="col-12" style="">
         <img :src="$root.logo" alt="Welcome">
         <h3 style="float:right;">{{$root.tenant.tenantName}}</h3>
      </div>
    </b-row>
    <b-row class="row" style="padding:1em;">
      <div style="">
        <h2 data-toggle="collapse" href="#collapseNews" role="button" >News </h2>
        <b-row id="collapseNews" style="padding-bottom:50vh;" class="">
          <news-item   v-for="(news,index) in newsList"
            :key="index" 
            class="col col-xl-4 col-md-5 col-sm-12 col-xm-12"
            :title="news.title"
            :content = "news.description"
            :isNew = "moment($root.portal.lastLogon) < moment(news.updatedAt)"
          />
        </b-row>
      </div>
    </b-row>

  </b-container>
</template>

<script>
/* eslint-disable */

import Recommendation from "../components/Recommendation";
import NewPost from "../components/NewPost";
import Posting from "../components/Posting";
import MyProfile from "../components/MyProfile";
import NewsItem from "../components/NewsItem";
import GeneralService from "../services/GeneralService";
import Currency from "currency.js";
import Moment from "moment-timezone";

// @ is an alias to /src
  export default {
    name: 'home',
    components: {
      NewPost,
      Posting,
      MyProfile,
      NewsItem,
      Recommendation
    },
    data() {
      return {
        slide   : 0,
        totals: null,
        postings: [],
        newsList: []
      }
    },
    async mounted() {
      this.$root.setFooterButtonsDefault();
      this.getNews();
    },
    methods: {
      currency(c) {
        return Currency(c);
      },
      moment(d) {
        return Moment(d);
      },
      logoff() {
        // call the Logout() on the api
        this.$root.logoff();
        // localStorage.removeItem('x-access-token');
        this.$router.push("/");
      },
      register() {
        this.$router.push( { name: 'register' });
      },
      async getNews() {
        let response = await GeneralService.getNews( "news-item" , "teams" );
        this.newsList = response.data;
      }
    }
  }

</script>
<style lang="scss" scoped>
  /* use custom.scss in assets */
</style>

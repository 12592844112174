<template>
 <b-container class="main-page"> 
    <b-row>
      <b-col>
        <h2>Options</h2>
        <p>App Version: {{appVersion}}</p>
        <hr>
        <ul class="menu-list">
          <!-- <li>Preferences</li> -->
          <!-- <li @click="purchaseHistory">Purchase History</li> -->
          <li @click="profile">Profile</li>
          <li @click="updateApp">Update This App</li>
          <br>
          <hr>
          <li @click="$root.logoff();">Log Out</li>
        </ul>

      </b-col>
     
    </b-row>
 </b-container>

</template>

<script>
/* eslint-disable */
import Authentication from '@/services/AuthenticationService'
import GeneralService from '@/services/GeneralService'
import { version } from '../../package.json'

export default {
  name: 'login',
  data () {
    return {
      pageReady: true,
      appVersion: version
    }
  },
  async mounted() {
  },
  methods: {
    home() {
      this.$router.push( { name: 'home' });
    },
    cancel() {
      this.$router.push({name:'home'});
    },
    updateApp() {
      location.reload(true);
    },
    purchaseHistory() {
      this.$router.push({name: 'purchaseHistory' });
    },
    profile() {
      this.$router.push({name:'profile'});
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

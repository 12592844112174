<template>
  <div v-if="showMenu" class="footer-bottom">
    <router-link v-for="(button,index) in $root.footerButtons" :key="index" class="footer-button" :disabled="!button.to.path" :to="button.to" >
      <div @click="click(button)">
        <i :class="button.icon"></i>
        <div>{{button.title}}</div>
      </div>
    </router-link>
  </div>
</template>

<script>
/* eslint-disable */
// import MenuDialog from './MenuDialog'

export default {
  
  components: {
    //MenuDialog
  },
  computed: {
  },
  data() {
    return {
      showMenu: true
    }
  },
  methods: {
    click(e) {
      if( !e.to || !e.to.click) {
        return;
      }
      let target = e.to.click;
      if( target ) {
        console.log( "(%s) footer->emitting [%s]" , this.$root.enablefooter, target );
        this.$emit( target , e );
      }
    }
  }
}
</script>

<style lang="scss" scoped  >
 /* use custom.scss in assets */


</style>

<template>
  <div class="container main-page">
    <h2>iCare ezPay</h2>
    <div v-if="! receiptData">
      <p>Present this image to the cashier</p>
      <div style="margin-top:2em;text-align:center;vertical-align:center;">
        <qrcode-vue  style="padding:0;margin:0;" :value="codeData" :size="size" level="H"></qrcode-vue>
      </div>
      <h5 class="blink" style="margin-top:1em;text-align:center;">({{checkTimeout}}) Waiting... </h5>
      <br>
      <div style="text-align:center;">
        <b-button @click="cancel()">Cancel</b-button>
      </div>
    </div>
    
    <div v-if="receiptData">
      <h3>Thank you</h3>
      <h4>Transaction Complete</h4>
      <pre class="receipt-format1">
        {{receiptData}}
      </pre>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Authentication from '@/services/AuthenticationService'
import GeneralService from '@/services/GeneralService'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'payment',
  components: {
    QrcodeVue
  },
  data () {
    return {
      checkTimeout: 30,
      pageReady   : true,
      codeData    : this.$root.badge.badgeNumber,
      size        : 200,
      receiptData : null,
      timeoutTimer: null,
      checkTimer  : null
    }
  },
  async mounted() {
    let self=this;
    self.receiptData = null;
    if( ! self.$root.badge ) { // in case we don't have this.
      self.$root.loadConfig();
    }
   // setTimeout( self.mockTransaction , 6000 );
   self.timeoutTimer = setTimeout( self.cancel, 30000 );
   self.beginCheckForTransaction();
  },
  destroyed() {
    this.clearTimers();
  },
  methods: {
    home() {
      this.$router.push( { name: 'home' });
    },
    cancel() {
      this.$router.push({name:'home'});
    },
    clearTimers() {
      let self=this;
      console.log( "clearTimers()" );
      if( self.timeoutTimer ) {
        clearTimeout( self.timeoutTimer );
        self.timeoutTimer = null;
      }

      if( self.checkTimer ) {
        self.checkTimeout = 0;
        clearTimeout( self.checkTimer );
        self.checkTimer = null;
      }
    },
    beginCheckForTransaction() {
      let self=this;
      self.checkTimeout = 30;
      self.checkForTransaction();
    },
    async checkForTransaction() {
      let self = this;
      console.time("get-trans");
      let response = await GeneralService.getNewTransaction( self.$root.TZ );
      console.timeEnd( "get-trans");
      let trans = response.data;

      console.log( "checkForTransaction()" , trans );

      // if( trans && trans.length > 0 && trans[0].receipt && trans[0].receipt[0].length > 0 ) {
      if( trans && trans.length > 0 && trans[0].receipt ) {
        console.log("Trans = " , trans );
        self.receiptData = trans[0].receipt[0];
        this.clearTimers();
      } else {
        self.checkTimeout -= 2;           // retrigger if not received.
        if( self.checkTimeout > 0  ) {
         self.checkTimer = setTimeout(  self.checkForTransaction , 2000 );
        }
      }
    },
    mockTransaction() {
      let self = this;

      this.receiptData = `
                iPos Cafe                
          Brandon, Mississippi          
          Best Coffee in town!          
4291 TTwo
========================================
Tr 1            Chk 5499           Gst 1
         Wed, 28-Oct-20 04:54pm         
========================================
   1 Chicken Nuget Meal             6.00
   1 Coke                           0.75
     Online Charge                 -6.75
      Approved: 04032fda                
      7107                              
      Courtney                          
      Bal: 1315.02                      

                   # Items(2)           
                    Sub Total       6.75
                        Total       6.75
                  Amount Paid       6.75
                  Balance Due       0.00
                                        
      Thank you for your business       
           Please come again            
                   -                    
          Manager: Mark Jones           
             (601) 123-4567   `
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

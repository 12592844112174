/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import PublicHome from '../publicViews/Main.vue'
import Login from '../publicViews/Login.vue'
import Register from '../publicViews/Register.vue'

import Home from '../views/Home.vue'
import More from '../views/More.vue'
import Applications from '../views/Applications.vue'
import PurchaseHistory from '../views/PurchaseHistory.vue'
import Payment from '../views/Payment.vue'
import Order from '../views/Order.vue'
import Profile from '../views/Profile.vue'

/**
 * Applications
 */

//import Tickets from '../apps/Tickets'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'public',
    component: PublicHome, // for now
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: { authRequired: false }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: { authRequired: true }
  },
  {
    path     : '/apps',
    name     : 'apps',
    component: Applications,
    meta     : { authRequired: true }
  },
  {
    path     : '/payment',
    name     : 'payment',
    component: Payment,
    meta     : { authRequired: true }
  },
  {
    path: '/purchaseHistory',
    name: 'purchaseHistory',
    component: PurchaseHistory,
    meta: { authRequired: true }
  },
  {
    path: '/order',
    name: 'order',
    component: Order,
    meta: { authRequired: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { authRequired: true }
  },
  {
    path: '/more',
    name: 'more',
    component: More,
    meta: { authRequired: true }
  },
  {
    path: '/apps/tickets/:action?',
    name: 'tickets',
    // component: Tickets,
    component: () => import(/* webpackChunkName: "tickets" */ '../apps/tickets/Index.vue'),
    meta: { authRequired: true }
  },
  {
    path: '*',
    name: 'notFound',
    component: Login,
    meta: { removeToken: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
})

router.beforeEach((to, from, next) => {
  const authRequired = to.matched.some(route => route.meta.authRequired)
  let token;// = null

  if (to.matched.some(route => route.meta.removeToken)) {
    console.log('******** Hit unknown route - removing token & hitting login')
    localStorage.removeItem('x-access-token')
  } else {
    token = localStorage.getItem('x-access-token')
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  if (authRequired && !(token)) {
    next('/')
  } else {
    next()
  }
})

export default router

<template>
  <div class="main-page">
    
    <div v-show="!receipt">
      <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xm-12"> 
        <h4>Current balance{{(totals.length>1)?"s" : "" }}</h4>
        <div v-for="(t,index) in totals" :key="index" style="width:100%;display:flex;"> 
          <div style="margin-left:1em;font-weight:600;min-width:175px;">{{t.title}}</div><div style="padding-left:1em;min-width:90px;text-align:right;">{{currency(t.balance)}}</div>
        </div>
      </div>
      <hr>
      <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xm-12"> 
        <h4>Transactions</h4>
        <p style="margin:-10px 0;padding:0;font-size:.6em;">(max 60 days)</p>
        <ul class="tr-listing">
          <li v-for="(t,index) in transactions" :key="index"  class="tr-listing-body" @click="showReceipt(index)">
            <div style="display:inline;">
              <i v-if="!t.isPayment" class="fa fa-dollar tr-charge-icon"></i>
              <i v-if="t.isPayment" class="fa fa-dollar tr-payment-icon"></i>
              <div>
                <div class="tr-amount">${{(t.isPayment) ? currency(t.tenderAmount).multiply(-1) : currency(t.tenderAmount)}}</div>
                <div class="tr-revenue-center">{{revenueCenter( t.revenueCenter)}}</div>
                <div class="tr-title"><span v-if="t.isPayment">Payment</span> {{t.title}}</div>
                <div class="tr-date">{{ moment(t.transactionDate).format("LLLL")}}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div v-show="receipt" style="padding:1em 1em 0 1em;" @click="receipt=null" >
      <h2 >Receipt</h2>
      [close]
      <div class="receipt-format1">
        {{receipt}}
      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable */
import Authentication from '@/services/AuthenticationService'
import GeneralService from '@/services/GeneralService'
import Moment from "moment-timezone";
import Currency from "currency.js";

export default {
  name: 'login',
  data () {
    return {
      pageReady     : true,
      receipt       : null,
      totals        : [],
      transactions  : [],
      revenueCenters: null
    }
  },
  async mounted() {
    Promise.all([
      this.getTotals(),
      this.getTransactions(),
      this.getRevenueCenters()
    ])
  },
  methods: {
    moment(d) {
      return Moment(d);
    },
    currency(c) {
      return Currency(c);
    },
    home() {
      this.$router.push( { name: 'home' });
    },
    cancel() {
      this.$router.push({name:'home'});
    },
    showReceipt( index ) {
      console.log( "showReceipt" , index , this.transactions[index].receipt );
      this.receipt = this.transactions[ index ].receipt[0];
    },
    revenueCenter( id ) {
      let rId = parseInt( id );
      let rc = _.find( this.revenueCenters , {revenueCenterId: rId });
      if( rc ) {
        return rc.title;
      } else {
        return "** " + id.toString();
      }
    },
    async getTotals() {
      try {
        let results = await GeneralService.getBalance();
        this.totals = results.data;
      } catch( error ) {
        console.log( error.message );
      }
    },
    async getTransactions() {
      try {
        let results = await GeneralService.getTransactions()
        this.transactions = results.data;
      } catch( error ) {
        console.log( error.message );
      }
    },
    async getRevenueCenters() {
      try {
        if( this.$root.revenueCenters ) {
          this.revenueCenters = this.$root.revenueCenters;
          return;
        }
        let results = await GeneralService.getRevenueCenters();
        this.revenueCenters = results.data;
        this.$root.revenueCenters = this.revenueCenters;
      } catch( error ) {
        console.log( error.message );
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>

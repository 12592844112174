<template>
  <div class="posting-component">
    <div class="posting-author" v-if="posting.author">
      <i class="posting-ellipsis fas fa-ellipsis-h"></i>
      <img :src="posting.authorImg">
      <div class="posting-author-name">{{posting.author}} <i class="posting-separator fas fa-circle"></i> 1st</div>
      <div class="posting-author-tag">{{posting.authorTag}}</div>
      <div class="posting-date">{{ moment(posting.postingDate).fromNow()}}</div>    
    </div>
    <div class="posting-title">{{posting.title}}<b-badge class="posting-badge" v-if="posting.newFlag">New</b-badge></div>
    <div class="posting-description">{{posting.description}}</div>
    <hr>
    <div class="posting-comment"><i class="far fa-thumbs-up" @click="like()"></i><span v-if="posting.likes">{{posting.likes}} Likes</span></div>
  </div>
</template>
<script>
import Moment from "moment";
export default {
  props: { 
    posting: { type: Object, default: () => {}}
  },
  methods: {
    moment(d) {
      return Moment(d);
    },
    like() {
      this.posting.likes++;
      // update the DB
    }
  }
}
</script>
<style lang="scss" >

.posting-component {
  border:1px solid gray;
  border-radius:.5em;
  background: #eee;
  padding:1em;
  margin-left:0;
  margin-bottom:1em;

  .posting-badge {
    background:red;
    font-size:.5em;
    vertical-align:top;
    border-radius:2em;
  }
  .posting-author {
    width:100%;
    font-style: italic;
    font-weight:400;
    font-size:.9em;
    display:inline-block;
    img {
      height:64px;
      border-radius:50%;
      float:left;
    }

    .posting-separator {
      font-size:.5em;
      vertical-align:middle;
    }

    .posting-ellipsis {
      cursor:pointer;
      padding:.5em;
      font-size:1em;
      border-radius:50%;
      vertical-align:middle;
      float:right;
      color:gray;
    }
    .posting-ellipsis:hover {
      background:#ddd;
      padding:.5em;
      border-radius:50%;
      vertical-align:middle;
    }
    .posting-author-name {  // author name
      display:block;
      font-weight:700;
      font-family: Arial, Helvetica, sans-serif;
      font-size:1.1em;
      margin-bottom:0;
      margin-left:75px;
    }
    .posting-author-tag {  // author title
      font-weight:400;
      font-size:.9em;
      margin-left:75px;
      display:block;
    }
    .posting-date {  // posting date/time
      display:block;
      font-size:.9em;
      font-weight:400;
      margin-top:-4px;
      margin-left:75px;
    }
  }
  .posting-title {
    padding-left:.5em;
    font-size:1.1em;
    font-weight:500;
  }
  .posting-description {
    padding-left:.5em;
    line-height:1.15em;
    // font-family: Arial, Helvetica, sans-serif;
  }
  .posting-comment {
    span {
      padding-left:1em;
      font-size:.8em;
      margin-right:1em;
    }
    i {
      padding:.5em;
      border-radius:50%;
      font-size:1.25em;
      cursor:pointer;
    }

    i:hover {
      padding:.5em;
      border-radius:50%;
      background:#ddd;
    }

  }
}
</style>
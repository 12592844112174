<template>
  <div class="news-item">
    <b-badge v-if="isNew" pill variant="danger" class="news-item-is-new">new</b-badge>
    <h4 v-if="title.substring(0,1)!=='-'">{{title}} </h4>
    <div id="div-content" class="news-item-content" v-html="content"></div>
    <!-- <div class="news-item-options">
      <i class="fa fa-camera"></i>
      <i class="fa fa-video"></i>
      <i class="fa fa-file"></i>
    </div>
    <div>Write an article on iCare Team</div> -->
  </div>
</template>
<script>
export default {
  props: {
    title  : { type: String, default: "" },
    content: { type: String, default: "" },
    isNew  : { type: Boolean, default: false }
  },
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
  /* Styles managed in assets/css/custom.scss  */
</style>
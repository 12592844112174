<template>
  <div class="container main-page blue-container">
    <div class="register-form">
      <div class="row">
        <img src="@/assets/img/iCareTeamLogo.png" class="page-logo" alt="iCare Team Logo" @click="$router.push('/')" />
        <div class="col-12 mt-2 mb-1 ml-auto mr-auto">
          <h4>Register for access</h4>
          <p style="margin:1em;font-size:.8em;">Please provide the following information. When all fields are properly entered, the register button will activate
            at the bottom of this form.</p>
        </div>
        <form class="col-12 mt-0">
          <div class="position-relative">
            <b-form-input class="user-input mb-3 pl-5" id="signup-code" trim v-model="signupCode" placeholder="Signup Code (See admin)" :state="signupCodeState()"></b-form-input>
            <i class="fa fa-user user-icon" />
          </div>
          <b-form-invalid-feedback id="signup-code-feedback">
            This code was provided by your system administrator
          </b-form-invalid-feedback>

          <div>
            <div class="position-relative">
              <b-form-input class="user-input mb-3 pl-5" id="badge-number" trim v-model="badgeNumber" placeholder="Your current badge number" :state="badgeState()"></b-form-input>
              <i class="fa fa-id-badge user-icon" />
            </div>
            <b-form-invalid-feedback id="badge-number-feedback">
              This is the number encoded on your badge
            </b-form-invalid-feedback>
          </div>

          <div class="position-relative">
            <b-form-input class="user-input mb-3 pl-5" id="last-name" trim v-model="lastName" placeholder="Your Last Name" :state="nameState()"></b-form-input>
            <i class="fa fa-user user-icon" />
          </div>
          <b-form-invalid-feedback id="last-name-feedback">
            Your last name as matching your iCare account
          </b-form-invalid-feedback>

          <p style="margin:1em;font-size:.8em;">The items above are to help find your existing iCare account and the items below are to setup your mobile login credentials.</p>
          <hr>

          <div class="position-relative">
            <b-form-input class="email-input mb-3 pl-5" id="email" v-model="email" placeholder="Your Email Address" :state="emailState()"></b-form-input>
            <i class="fa fa-envelope email-icon" />
          </div>
          <b-form-invalid-feedback id="email-feedback">
            Please enter a valid email address
          </b-form-invalid-feedback>

          <div class="position-relative">
            <b-form-input class="password-input mt-0 pl-5" type="password" v-model="password" placeholder="Password" :state="passwordState()"></b-form-input>
            <i class="fa fa-lock password-icon" />
          </div>
          <div class="position-relative">
            <b-form-input class="password-input mt-2 pl-5" type="password" v-model="confirmPassword" placeholder="Confirm Password" :state="passwordState()"></b-form-input>
            <i class="fa fa-lock password-icon" />
          </div>
          <div class="position-relative">
            <b-checkbox class="mt-4 mb-0 pl-5" style="color:white;" v-model="agree">I agree to the terms and conditions</b-checkbox>
          </div>
        </form>
        <div class="col-12 mt-0">
          <b-button :disabled="isInvalid()" class="w-100 register-button" variant="warning" @click="register"><span v-if="isInvalid()">Please provide
              details above</span><span v-else>Ok, Create My Account</span></b-button>
          <div class="cancel-link" @click="$router.push('/')">Cancel</div>
        </div>
        <div v-if="errorMessage" class="col-12 font-weight-bolder text-center mt-4 text-danger">{{errorMessage}}</div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Authentication from '@/services/AuthenticationService'

export default {
  name: 'login',
  components: {
  },
  data () {
    return {
      hasRegistered  : false,
      signupCode     : "",
      signupCodeError: false,
      badgeNumber    : "",
      firstName      : "",
      lastName       : "",
      agree          : false,
      email          : "",
      password       : "",
      confirmPassword: "",
      userType       : "",
      errorMessage   : ""
    }
  },
  async mounted () {
  },
  methods: {
    home() {
      this.$router.push( { name: "login" });
    },
    signupCodeState() {
      if( this.signupCodeError ) {
        return false;
      }
      return ( this.signupCode.length > 5 ) ? true : false;
    },
    nameState() {
      return ( this.lastName.length > 0 ) ? true : false;
    },
    badgeState() {
      return ( this.badgeNumber.length > 0 ) ? true : false;
    },
    emailState() {
      return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) ? true : false;
    },
    passwordState() {
      if( this.password.length>0 ) {
        if( this.password !== this.confirmPassword ) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
    isInvalid() {
      if( ! this.agree ) {
        return true;
      }

      if( this.email.length === 0 ) {
        return true;
      }

      if( this.password !== this.confirmPassword ) {
        return true;
      }
      return false;
    },
    async register() {
      let self=this;
      this.errorMessage = "";

      if( ! this.agree ) {
        this.errorMessage = "Please accept the agreement to be notified";
        return false;
      }

      if( this.signupCode.length === 0 ) {
        this.errorMessage = "Please enter your Company Code to continue";
        return false;
      }

      if( this.lastName.length === 0 ) {
        this.errorMessage = "Please enter your LAST NAME to continue";
        return false;
      }

      if( this.email.length === 0 ) {
        this.errorMessage = "Please provide an email address to continue";
        return false;
      }

      if( this.password !== this.confirmPassword ) {
        this.errorMessage = "Your passwords do not match";
        return false;
      }

      if( this.isInvlaid ) {
        return false;
      }

      try {
        let creds = {
          signupCode : this.signupCode,
          lastName   : this.lastName,
          badgeNumber: this.badgeNumber,
          email      : this.email,
          password   : this.password,
        }

        let response = await Authentication.link( creds );
        let user = response.data;

        if( response.status === 200 ) {
          await this.$dialog.alert('Thank you for registering. \n\nWe have sent you an email with an activation code to activate your account.  If you have any questions, please direct your inquiries to Support at support@icare.team. Thanks for using iCare TEAMS');
          this.$router.push( { name: 'login' });
        } else {
          this.errorMessage = user.message;
        }

      } catch( error ) {
        console.log( "error" , error.message );
        if( error.response ) {
          this.errorMessage = error.response.message;
        } else {
          this.errorMessage = error.message;
        }
        return false;
      }

    }
    
  }
}
</script>

<style lang="scss" scoped>


</style>

<template>
  <div class="container main-page">
    <div class="row">
      <b-col class="col-md-6 col-lg-6">
        <img class="page-logo" alt="iCare Team" src="../assets/img/iCareTeamLogo.png"  @click="$router.push({name:'login'})">
        <b-button class="button-login" @click="$router.push({name:'login'})">Existing User, Log In</b-button>
        <hr>
        <h1>Welcome</h1>

        <p>If you are a returning guest, please use the yellow button above to log in</p>
        <p>If you do not have a login account, please use the "Register for Access" link, at the bottom (you may need to scroll down), to get things rolling.  You will need your company signup code as well as your badge number to continue the registration part.  If you do not have these, then please contact your system administrator.</p>
        <hr>
        <div class="button-register" style="margin-top:2em;">
          <a @click="register()">Register for Access</a>
        </div>
        <br>
        <br>
        <address>
          <div style="font-size:2em;">iCare Team</div>
          <a style="color:blue;text-decoration:underline;" href="https://www.newcare.international" target="_blank">Newcare International</a><br>
          POB 5984<br>
          Brandon, MS 39047<br>
          <a href="mailto:info@icare.team?subject=iCare Team, Information Request">Email us (info@icare.team)</a>
        </address>

        <br>
        
      </b-col>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
export default {
  name: 'public-main',
  created() {
    let token=localStorage.getItem('x-access-token');
    if( token ) {
      this.$router.push( { name:'login' });
    }
  },
  data() {
    return {

    }
  },
  methods: {
    register() {
      this.$router.push( "/register" );
    }
  }
}
</script>
<style lang="scss" scoped>

.main-page {

  height:100%;
  width:100%;
  padding:2em;

  background: -moz-linear-gradient(top,  rgba(42,44,43,1) 0%, rgba(42,44,43,0.9) 52%, rgba(42,44,43,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(42,44,43,1) 0%, rgba(42,44,43,0.9) 52%, rgba(42,44,43,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,   rgba(42,44,43,1) 0%, rgba(42,44,43,0.9) 52%, rgba(42,44,43,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#659dbd', endColorstr='#d5dee5',GradientType=0 ); /* IE6-9 */

  color:white;

  .page-logo {
    background:#035a5b;
    padding:1em;
    border:4px solid #036c6e; 
    border-radius:2em;
    height:20vh;
  }
}

address {
  display: block;
  font-style: italic;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.button-login {
  /* font-size:.9em; */
  float:right;
  margin-top:2em;
  border-radius:1em;
  background:orange;
  color:black;
  vertical-align:bottom;
  text-align:center;
}

.button-register {
  text-decoration:underline;
  cursor:pointer;
}
.button-register:hover {
  font-style:bold;
  color:purple;
}

</style>

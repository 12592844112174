/* eslint-disable */
/**
 * Created by wscott on 01/20/2020.  Machine: {wolfdev2}
 *
 *  Revision History for Api ::
 *
 *      Initial Writing: 01/20/2020        wjscott
 *      ------------------------------------------------------------------------
 *
 *       1.
 *
 */

import axios from 'axios';
import constants from '@/constants';

export default () => {
  let token = localStorage.getItem('x-access-token');
  let ax    = axios.create({
                baseURL     : constants.PORTAL_APIURL,
                responseType: 'json',
                headers     : {
                  'Content-Type'  : 'application/json',
                  'Accept'        : 'application/json',
                  'applicationKey': constants.applicationKey,
                  'x-access-token': token
                },
                validateStatus: function (status) {
                  return status >= 200 && status <= 499 //500+ should throw an error
                }
              })

  return ax;
}

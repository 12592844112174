<template>
  <div class="profile-component">
    <h5>{{title}}</h5>
    <!-- <div class="user" >Alias: {{user.title}}</div> -->
    <div class="details" >Logon # {{user.logonCount}} {{moment(user.lastLogon).fromNow()}} from {{ user.lastIP }}</div>
  </div>
</template>
<script>
import Moment from "moment";

export default {
  props: { 
    user : {type: Object, default: () => {} },
    title: { type: String, defualt: "Profile Details" }
  },
  data() {
    return {
    }
  },
  methods: {
    moment(d) {
      return Moment(d);
    }
  }
}
</script>
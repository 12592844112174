<template>

<b-container fluid class="main-page">
    <h4>Profile</h4>
    <b-row>
      <b-col md="4" sm="12">
        <my-profile title="Previous Login" :user="$root.portal"/>
      </b-col>
      <b-col md="4" sm="12">
       <img style="border-radius:25%;margin-bottom:1em;" :src="$root.badge.pictureUrl" height=128/>
       <ul>
         <li>Badge #: {{$root.badge.badgeNumber}}</li>
         <li>Alias: {{$root.badge.alias}}</li>
         <li>First: {{$root.badge.firstName}}</li>
         <li>Last: {{$root.badge.lastName}}</li>
         <li>Email Receipt? {{($root.badge.Options.emailReceipt) ? "Yes" : "No" }}</li>
       </ul>
      </b-col>
    </b-row>
</b-container>
</template>
<script>

import Moment from "moment";
import MyProfile from "../components/MyProfile";

export default {
  components: {
    MyProfile
  },
  data() {
    return {
    }
  },
  methods: {
    moment(d) {
      return Moment(d);
    }
  }
}
</script>

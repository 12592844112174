<template>
  <nav class="header navbar navbar-expand navbar-dark bg-dark sticky-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="/home"><img class="header-logo" src="@/assets/img/iCareTeamLogo-150.png" alt="iCare Teams"></a>
      
      <button class="navbar-toggler" type="button" v-b-toggle.menu1 >
        <span class="navbar-toggler-icon"></span>
      </button>
      <b-collapse class="collapse navbar-collapse" id="menu1">
       
        <b-nav class="ml-auto" @click="profile()">
          <!-- <b-nav-item-dropdown id="user-nav" toggle-class="nav-link-custom"  :text="'Hi, ' + userAlias()">
            <b-dropdown-item class="nav-link-item" :to="{ path: 'logout' }">Logout</b-dropdown-item>
          </b-nav-item-dropdown> -->
          <div class="nav-text">Hi, {{userAlias()}}</div>
          <div><img class="nav-profile-image" :src="userProfileImage()"></div>
        </b-nav>
      </b-collapse>

    </div>
  </nav>
</template>

<script>
/* eslint-disable */

  export default {
    components: {
    },
    computed: {
    },
    data() {
      return {
        showMenu: false,
        mainMenu: []
      }
    },
    mounted() {
      console.log("header->mounted()");
      if( ! this.$root.tenant) {
        if( ! this.$root.loadConfig())  {
          this.$router.push( "/login");
        }
      }
    },
  methods: {
    userAlias() {
      if( this.$root.badge) {
        return this.$root.badge.alias;
      }
      return '';
    },
    userProfileImage() {
      if( this.$root.badge ) {
        if( this.$root.badge.pictureUrl ) {
          return this.$root.badge.pictureUrl;
        } else {
          return '';
        }
      }
      return '';
    },
    isLoggedIn () { // maybe this needs to be $store as this won't repaint the menu :(
      if (this.root.getAccessToken()) {
        return true
      }
      return false
    },
    logOff () {
      console.log("header logOff()")
      this.$root.logoff();
      setTimeout( this.$router.push({ name: 'login' }), 1);
    },
    profile() {
      setTimeout( this.$router.push( { name: "profile" }), 1);
    },
    showMenuDlg () {
      this.showMenu = !this.showMenu
    },
    changeRoute () {
      console.log('clicked changeRoute')
    }
  }
}
</script>

<style lang="scss">
 /* use custom.scss in assets */



</style>
